/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
// import { getUsers } from "services/AdminService";
import UserNameCell from "./components/UserNameCell";
import ProgressBar from "components/ProgressBar/ProgressBar";
import ActionCell from "./ActionCell";
import trashIcon from "../../assets/icons/trash.svg";
import ActionModal from "components/Modals/ActionModal";
// import { deleteUser } from "services/AdminService";
import { toast } from "react-toastify";
import UserFilterModal from "components/Modals/UserFilterModal";
import axios from "axios";

function MaterialsList() {
  const navigate = useNavigate();

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState();
  // const [users, setUsers] = useState([{ union_id: 123, userName: "Ameer", title: "Test" }]);
  const [materials, setMaterials] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [spinner, setSpinner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [filters, setFilters] = useState();
  const [filterModal, setFilterModal] = useState();

  const handleEdit = (id) => {
    //   navigate(`/admin/user-management/edit-user/${id}`);
  };

  const handleDetail = (id) => {
    //   navigate(`/admin/user-management/user-detail/${id}`);
  };

  const handleDelete = (id) => {
    //   setDeleteUserId(id);
    //   setDeleteModal(true);
  };

  const getMaterialsListing = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/material/all_materials`, {
        page: pageNo,
        pageSize: entriesPerPage,
      })
      .then((response) => {
        setMaterials(response?.data?.materials);
        setTotalPages(response?.data?.totalPages);
        setTotalEntries(response?.data?.totalRecords);
        setSpinner(false);
      })
      .catch((error) => {
        console.error("Error Fetching Materials!", error);
        setSpinner(false);
      });
  };

  const confirmUserDelete = () => {
    //   setSpinner(true);
    //   deleteUser(deleteUserId)
    //     .then((data) => {
    //       console.log(data);
    //       toast.success("User Deleted Successfully");
    //       setDeleteModal(false);
    //       getUsersListing();
    //       setSpinner(false);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setSpinner(false);
    //       toast.error(error?.message || "Something Went Wrong");
    //     });
  };

  useEffect(() => {
    setSpinner(true);
    getMaterialsListing();
    console.log(materials);
  }, [entriesPerPage, pageNo]);
  // }, [search, entriesPerPage, pageNo, deleteModal, filters]);

  const transformedData = materials.map((material) => ({
    ...material,
    // action: (
    // <ActionCell
    //   handleDelete={() => handleDelete(user._id)}
    //   handleEdit={() => handleEdit(user._id)}
    //   handleDetail={() => handleDetail(user._id)}
    //   type="User"
    // />
    // ),
    // userName: <UserNameCell img={user?.image} name={user?.userName} />,
    // title: user?.title,
  }));

  const dataTableData = {
    columns: [
      // { Header: "", accessor: "checkbox" },
      /* eslint-disable react/prop-types */
      {
        Header: "Material Name",
        accessor: "Name",
        Cell: ({ value }) => (
          <div
            style={{
              maxWidth: "260px",
            }}
          >
            {value}
          </div>
        ),
      },
      { Header: "Manufacturer", accessor: "Manufacturer" },
      { Header: "Part Number", accessor: "PartNo" },
      { Header: "Supplier", accessor: "Vendors[0].Vendor.Name" },
    ],
    /* eslint-disable react/prop-types */
    rows: transformedData,
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox my={3}>
        <Card sx={{ overflow: "visible" }}>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <ArgonBox lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                List of Materials
              </ArgonTypography>
            </ArgonBox>
            {/* <Stack spacing={1} direction="row">
              <Link to="/admin/user-management/new-user">
                <ArgonButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{ fontWeight: "600" }}
                >
                  <span style={{ fontSize: "20px" }}>+</span>&nbsp; Add New User
                </ArgonButton>
              </Link>
            </Stack> */}
          </ArgonBox>
          {spinner && <ProgressBar />}
          <DataTable
            spinner={spinner}
            table={dataTableData}
            entriesPerPage={entriesPerPage}
            // canSearch
            search={search}
            setSearch={setSearch}
            totalPages={totalPages}
            setEntriesPerPage={setEntriesPerPage}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalEntries={totalEntries}
            alignItems={"left"}
            // canFilter
            showFilter={() => setFilterModal(true)}
          />
        </Card>
      </ArgonBox>
      {/* <UserFilterModal
        open={filterModal}
        handleClose={() => setFilterModal(false)}
        setFilter={setFilters}
      />
      <ActionModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        title="Delete User"
        icon={trashIcon}
        detail="Are you sure to delete User?"
        handleYes={confirmUserDelete}
        handleNo={() => setDeleteModal(false)}
      /> */}
    </DashboardLayout>
  );
}

export default MaterialsList;
