import { useState, useEffect } from "react";
import "./style.css";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Alert } from "@mui/material";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import { login, verifyLogin } from "../../../../services/authService";
import { PASSWORD_REGEX } from "constants";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { ToastContainer, toast } from "react-toastify";

// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-cover.jpg";

function Cover() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    verifyLogin()
      .then((verified) => {
        if (verified) {
          navigate("/admin/dashboard");
        }

        if (!verified) {
          navigate("/user/login");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const signIn = () => {
    // if (!email || !password) {
    //   toast.error("Email and Password required.");
    //   return;
    // }

    // if (password.length < 8) {
    //   toast.error("Password Must be 8 characters long.");
    //   return;
    // }

    // if (!password.match(PASSWORD_REGEX)) {
    //   toast.error(
    //     "Password must contain atleast 8 characters and must contain numbers, symbols and alphabets."
    //   );
    // }

    // login(email, password)
    //   .then((data) => {
    //     localStorage.setItem("user", JSON.stringify(data));
    //     localStorage.setItem("refresh_token", data?.refresh_token);
    //     localStorage.setItem("access_token", data?.access_token);

    //     if (rememberMe) {
    //       // localStorage.setItem('access_token',data?.access_token)
    //     }
    //     navigate("/admin/dashboard");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error(error?.response?.data?.error);
    //   });
    navigate("/admin/dashboard");
  };

  return (
    <CoverLayout
      // title="Welcome!"
      // description="Use these awesome forms to login or create new account in your project for free."
      image={bgImage}
    >
      <Card>
        <ToastContainer />{" "}
        <ArgonBox pt={3} px={3}>
          <ArgonTypography variant="h3" color="dark" fontWeight="bold" mb={1}>
            Welcome back
          </ArgonTypography>
          <ArgonTypography variant="body2" color="text">
            Enter your email and password to sign in
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox p={3}>
          <ArgonBox component="form" role="form">
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Email
              </ArgonTypography>
              <ArgonInput
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </ArgonBox>
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Password
              </ArgonTypography>
              <ArgonInput
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mt={4}>
              <ArgonButton color="primary" fullWidth onClick={signIn}>
                Sign In
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox pb={4} px={1} textAlign="center">
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Can&apos;t remember the password?{" "}
            <ArgonTypography
              component={Link}
              to="/user/reset-password"
              variant="button"
              fontWeight="regular"
              color="primary"
            >
              Reset Here
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
