import Default from "layouts/dashboards/default";
import Users from "layouts/users/user-list";
import Cover from "layouts/authentication/sign-in/cover";
import ResetPassword from "layouts/authentication/reset-password/cover";
import ChangePassword from "layouts/authentication/change-password/cover";
// import NewUser from "layouts/users/new-user/NewUser";
import userIcon from "./assets/icons/user.svg";
import userIconActive from "./assets/icons/user-white.svg";
// import homeIcon from "./assets/icons/home.svg";
// import homeIconActive from "./assets/icons/home-white.svg";
// import UserDetail from "layouts/users/user-detail/UserDetail";
// import SubUsersListing from "layouts/admin-sub-users/admin-user-list/index";
// import SubUserDetail from "layouts/admin-sub-users/admin-user-detail/UserDetail";
// import AdminNewUser from "layouts/admin-sub-users/add-new-admin-user/NewUser";
import Profile from "layouts/profile/Profile";
import EditProfile from "layouts/profile/EditProfile";
import ProfilePassword from "layouts/profile/ProfilePassword";
import Notifications from "layouts/notifications";
import MaterialsList from "layouts/materials";
import SuppliersList from "layouts/suppliers";
import ProjectsList from "layouts/projects";
import materialBlue from "./assets/icons/material-blue.svg";
import materialWhite from "./assets/icons/material-white.svg";
import projectBlue from "./assets/icons/projectsBlue.svg";
import projectWhite from "./assets/icons/projectsWhite.svg";
import supplierWhite from "./assets/icons/suppliersWhite.svg";
import supplierBlue from "./assets/icons/suppliersBlue.svg";

export const routes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Login",
    key: "login",
    layout: "user",
    route: "/user/login",
    component: <Cover />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Reset Password",
    key: "reset-password",
    layout: "user",
    route: "/user/reset-password",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Change Password",
    key: "change-password",
    layout: "user",
    route: "/user/update-password/:token",
    component: <ChangePassword />,
  },
];

export const sidebarRoutes = [
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Dashboard",
  //   key: "dashboard",
  //   layout: "dashboard",
  //   icon: <img src={homeIcon} alt="Home" />,
  //   iconActive: <img src={homeIconActive} alt="Home" />,
  //   route: "/admin/dashboard",
  //   component: <Default />,
  // },
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "User Management",
  //   key: "user-management",
  //   layout: "dashboard",
  //   icon: <img src={userIcon} alt="User" />,
  //   iconActive: <img src={userIconActive} alt="User" />,
  //   route: "/admin/user-management",
  //   component: <Users />,
  // },
  {
    type: "collapse",
    noCollapse: true,
    name: "Materials",
    key: "materials",
    layout: "dashboard",
    icon: <img src={materialBlue} alt="User" />,
    iconActive: <img src={materialWhite} alt="User" />,
    route: "/admin/materials",
    component: <MaterialsList />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Suppliers",
    key: "suppliers",
    layout: "dashboard",
    icon: <img src={supplierBlue} alt="User" />,
    iconActive: <img src={supplierWhite} alt="User" />,
    route: "/admin/suppliers",
    component: <SuppliersList />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Projects",
    key: "projects",
    layout: "dashboard",
    icon: <img src={projectBlue} alt="User" />,
    iconActive: <img src={projectWhite} alt="User" />,
    route: "/admin/projects",
    component: <ProjectsList />,
  },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/new-user",
  //   component: <NewUser />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/edit-user/:id",
  //   component: <NewUser />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/user-detail-certificate/:id/:btnId",
  //   component: <UserDetail />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/user-detail/:id",
  //   component: <UserDetail />,
  // },

  {
    layout: "dashboard",
    route: "admin/profile",
    component: <Profile />,
  },
  {
    layout: "dashboard",
    route: "admin/edit-profile",
    component: <EditProfile />,
  },
  {
    layout: "dashboard",
    route: "admin/change-password",
    component: <ProfilePassword />,
  },
  {
    layout: "dashboard",
    route: "admin/notifications",
    component: <Notifications />,
  },
];

export const supervisorRoutes = [
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "User Management",
  //   key: "user-management",
  //   layout: "dashboard",
  //   icon: <img src={userIcon} alt="User" />,
  //   iconActive: <img src={userIconActive} alt="User" />,
  //   route: "/admin/user-management",
  //   component: <Users />,
  // },
  {
    type: "collapse",
    noCollapse: true,
    name: "Materials",
    key: "materials",
    layout: "dashboard",
    icon: <img src={materialBlue} alt="User" />,
    iconActive: <img src={materialWhite} alt="User" />,
    route: "/admin/materials",
    component: <MaterialsList />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Suppliers",
    key: "suppliers",
    layout: "dashboard",
    icon: <img src={supplierBlue} alt="User" />,
    iconActive: <img src={supplierWhite} alt="User" />,
    route: "/admin/suppliers",
    component: <SuppliersList />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Projects",
    key: "projects",
    layout: "dashboard",
    icon: <img src={projectBlue} alt="User" />,
    iconActive: <img src={projectWhite} alt="User" />,
    route: "/admin/projects",
    component: <ProjectsList />,
  },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/new-user",
  //   component: <NewUser />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/edit-user/:id",
  //   component: <NewUser />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/user-detail-certificate/:id/:btnId",
  //   component: <UserDetail />,
  // },
  // {
  //   layout: "dashboard",
  //   route: "/admin/user-management/user-detail/:id",
  //   component: <UserDetail />,
  // },

  {
    layout: "dashboard",
    route: "admin/profile",
    component: <Profile />,
  },
  {
    layout: "dashboard",
    route: "admin/edit-profile",
    component: <EditProfile />,
  },
  {
    layout: "dashboard",
    route: "admin/change-password",
    component: <ProfilePassword />,
  },
  {
    layout: "dashboard",
    route: "admin/notifications",
    component: <Notifications />,
  },
];
